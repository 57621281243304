<template>
    <div class="case-conent catalogue">

        <!--左侧科目基本信息-->
        <div class="catalogueLf">
            <div class="inf-01">
                <div class="inf-back" @click="ToCourseware()"></div>
                <div class="triangle"></div>
                <div class="book-Info">

                    <img v-if="queryResourcelistData.cover !=null" :src="queryResourcelistData.cover" alt="">
                    <img v-else src="../../assets/images/iconFm2.png" alt="">
                    <div class="T-01">{{ queryResourcelistData.name }}</div>
                    <div class="T-02">
                        <!--            {{queryResourcelistData.press}}-->
                        <div class="d-rg">
                            <el-tooltip class="item" effect="dark" :content="'出版社：'+queryResourcelistData.press"
                                        placement="top">
                                <span>出版社：{{queryResourcelistData.press}}</span>
                            </el-tooltip>

                        </div>
                    </div>
                </div>
            </div>
            <div class="inf-02">
                <div class="inf-time">
                    <el-tooltip class="item" effect="dark" :content="'上架时间：'+queryResourcelistData.time"
                                placement="top">
                        <span>上架时间：{{ queryResourcelistData.time }}</span>
                    </el-tooltip>

                </div>
                <div class="inf-j"></div>
                <div class="inf-cone">
          <span v-if="queryResourcelistData.introduction !=null">
            {{ queryResourcelistData.introduction }}
          </span>
                    <span v-else>
            暂无
          </span>
                </div>
            </div>
        </div>
        <!--右侧目录基本信息-->
        <div class="catalogueRg">
            <div class="tRreeName">{{ queryResourcelistData.name }}</div>
            <div class="inf-03">
                <div class="inf-zT" v-if="queryResourcelistData.isBuy !=1">
                    <h2>原价:{{ queryResourcelistData.totalPrice }}学币</h2>
                    <div class="zT">
                        <span>整本购买更便宜</span>
                        <el-button @click="buyOneBook()" type="warning" plain size="small" class="btnA"
                        >{{ queryResourcelistData.totalDiscount }}学币
                        </el-button>
                    </div>
                </div>
                <div class="inf-zT" v-else></div>
            </div>
            <div class="tRreeBox">
                <el-tree
                        :data="ResourcelistData"
                        :props="defaultProps"
                        :default-expanded-keys="[2, 3]"
                        node-key="id"
                        default-expand-all
                        :expand-on-click-node="false">
                          <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>
                                <span>
                                <i  v-if="data.level == null" class="circle3 el-icon-caret-right1"></i>
                                <i  v-if="data.level == 1 " class="circle1 el-icon-caret-right1"></i>
                                <i  v-else-if="data.level == 3 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                                <i  v-else-if="data.level == 2 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                                <i  v-else-if="data.level == 3 &&data.isUnit!=1" class="circle3 "></i>
                                <i  v-else-if="data.level == 4 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                                <i v-if="data.level == 1 &&data.isUnit!=3" class="ico-tree-2"><img src="../../assets/images/ico-tree-1.png" alt=""></i>
<!--                                <i  v-if="data.isResoure === 1" class="circle2 el-icon-caret-right1"></i>-->
                                <i v-if="data.isResoure === 1" class="ico-tree-2"><img src="../../assets/images/ico-tree-2.png" alt=""></i>
                                </span>
                                {{ data.resourceName }}
                            </span>
                            <span>
                              <el-button type="warning" plain v-if="data.isPrice === 1 &&  data.isBuy === 0 " @click="buyOneD(data)" size="mini" icon="el-icon-bangzhu"
                              >{{ data.price }}学币
                              </el-button>
                              <el-button type="danger" plain @click="tosNoBuyDetails(data)" v-if="data.isResoure === 1 && data.isBuy == 0 "
                                         size="mini"
                                         icon="el-icon-view"
                              >预览
                              </el-button>
                              <el-button type="danger" v-if="data.level == 2 &&data.isUnit==1&&data.isBuy!=0"
                                         @click="toUnitTest(data)" size="mini">单元测试
                              </el-button>
                              <el-button type="danger" v-if="data.isResoure === 1 && data.isBuy == 1"
                                         @click="tosDetails(data)" size="mini"
                              >进入学习
                              </el-button>
                            </span>
                          </span>
                </el-tree>
            </div>

        </div>
        <el-dialog
                title=""
                :visible.sync="buyDialogA1"
                :close-on-click-modal="false"
                :modal-append-to-body='false'
                width="600px"
                class="buyDialogA1"
        >
            <div class="buyDialogA1-01">
                <div v-if="checkAmountData.sumAmount>=checkAmountData.totalDiscount">
                    <p >我们愿将这本书赠送给您，以感谢您的厚爱！</p>
                    <div class="logA" v-if="checkAmountData.sumAmount>=checkAmountData.totalDiscount">
                        <span>0</span> 学币
                    </div>

                </div>
                <div v-else>
                    <div class="logA">
                        <span>{{HtotalPrice}}</span> 学币
                    </div>
                    <p>应付:{{checkAmountData.totalDiscount}} - 已付:{{checkAmountData.sumAmount}}</p>
                </div>


                <p>余额：{{checkAmountData.account}} 学币</p>
            </div>
            <div class="buyDialogA1-02">
                <p>图书名称</p>
                <div class="logA">
                    <span>《{{ queryResourcelistData.name }}》</span>
                </div>
            </div>
            <div class="buyDialogA1-03 cursor" @click="buyTBookA1()">
                购买
            </div>
            <!---->
        </el-dialog>
        <el-dialog
                title=""
                :visible.sync="buyDialogA2"
                :close-on-click-modal="false"
                :modal-append-to-body='false'
                width="600px"
                class="buyDialogA1"
        >
            <div class="buyDialogA1-01">
                <p>余额：{{checkAmountData.account}} 学币</p>
            </div>
            <div class="buyDialogA1-02">
                <p>图书名称</p>
                <div class="logA">
                    <span>《{{ queryResourcelistData.name }}》</span>
                </div>
            </div>
            <div class="buyDialogA1-03 cursor" @click="buyTBookA()">
                购买
            </div>
            <!---->
        </el-dialog>
        <el-dialog
                title=""
                :visible.sync="buyDialog"
                :close-on-click-modal="false"
                :modal-append-to-body='false'
                width="1000px"
                class="buyDialogA1"
        >
            <div class="buyDialogA1-01">
                <div class="logA">
                    <span>{{ Dprice }}</span> 学币
                </div>
                <p>余额：{{checkAmountData.account}} 学币</p>
            </div>
            <div class="buyDialogA1-02">
                <p>图书名称</p>
                <div class="logA">
                    <span>《{{ queryResourcelistData.name }}》</span>
                </div>
            </div>
            <div class="buyDialogA1-02">
                <p>课时名称</p>
                <div class="logA">
                    <span>《{{ reeName }}》</span>
                </div>
            </div>
            <div class="buyDialogA1-03 cursor" @click="toBuyOne()">
                购买
            </div>
        </el-dialog>
        <el-dialog
                title=""
                :visible.sync="buyCgDialog"
                :close-on-click-modal="false"
                :modal-append-to-body='false'
                class="buyDialogA1"
                width="900px"
        >
            <div class="buyDialogA1-04">
                <i class="el-icon-success"></i><span>购买成功</span>
            </div>

            <div class="buyDialogA1-05">
                <el-button @click="tobuyCg()" round
                           style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff"
                           type="primary">确定
                </el-button>
            </div>
        </el-dialog>
        <!-- 购买弹框-->
        <!--  <el-dialog width="600px" :modal-append-to-body='false'>-->
        <!--    <div class="dialogHd"-->
        <!--         style="text-align: center;height: 50px; line-height: 50px;font-size: 14px;font-weight: bold">-->
        <!--      是否支付<span style="color: #FF9100;padding: 0 10px;font-size: 20px">2</span>学币购买本学历案-->
        <!--    </div>-->
        <!--    <template #footer>-->
        <!--      <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">-->
        <!--        <el-button @click="regObj()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>-->
        <!--      </div>-->
        <!--    </template>-->
        <!--  </el-dialog>-->
        <!-- 学币不足弹框-->
        <el-dialog class="buyDialogA1" :visible.sync="InsuDialog" :close-on-click-modal="false" :modal-append-to-body='false' title=""
                   left width="900px">
            <div class="buyDialogA1-04">
                <i class="el-icon-warning"></i><span>您的学币不足，请充值！</span>
            </div>
            <div class="buyDialogA1-05">
                <el-button @click="toRecharge()" round
                           style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">
                    确定
                </el-button>
            </div>
        </el-dialog>
        <!-- 学币不足弹框-->
        <!--    <el-dialog :visible.sync="buyDialog" :close-on-click-modal="false" width="600px" center>-->
        <!--      <div class="dialogHd"-->
        <!--           style="text-align: center;height: 50px; line-height: 50px;font-size: 20px;font-weight: bold">-->
        <!--        <el-icon color="#359552" style="vertical-align: middle" :size="50">-->
        <!--          <circle-check-filled/>-->
        <!--        </el-icon>-->
        <!--        购买成功-->
        <!--      </div>-->
        <!--      <template #footer>-->
        <!--        <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box">-->
        <!--          <el-button @click="regObj()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>-->
        <!--        </div>-->
        <!--      </template>-->
        <!--    </el-dialog>-->
    </div>

</template>


<script>
    import {
        queryResourceObj,
        buyOneObj,
        buyOneBookObj,
        checkAmountObj,
        getStuByUserNameObj
    } from '@/api/catalogue'

    export default {
        name: '',
        data() {
            return {
                reeName: '',
                HtotalPrice: '',
                buyDialogA1: false,
                buyDialogA2: false,
                buyDialog: false,// 购买
                InsuDialog: false,// 学币不足
                buyCgDialog: false,// 购买成功
                queryResourcelistData: {},
                checkAmountData: {},
                Dprice: null,
                DfolderId: null,
                Did: null,
                ResourcelistData: [],
                total_price: '',// 原价
                total_discount: '',// 现价
                defaultProps: {
                    children: "list",
                    label: "resourceName"
                },
                ResisBuy:null,

                query: {
                    yearId: null,
                    subject: null,
                    grade: null,
                    term: null,
                    studySection: null,
                    textbookEdition: null,
                    Series: "",
                },
            }
        },
        created() {
            this.EducationCase = this.$route.query.EducationCase;
            this.clickFlag = this.$route.query.clickFlag;
            this.bookId = this.$route.query.bookId;
            this.totalPrice = this.$route.query.totalPrice;
            this.totalDiscount = this.$route.query.totalDiscount;
            this.stu = this.$route.query.stu
            this.queryRes()
            this.listObj()
            this.getStuByUser()
            this.checkAmount()
        },
        methods: {
            //返回
            back() {
                this.$router.go(-1)//就可以回到上一页。
                //history.go(-1)//是回到浏览器上一页
            },
            // 查询个人信息
            getStuByUser() {
                getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        //this.StuByUser = res.data
                        this.$store.commit('saveStuByUser', res.data)
                    }
                })

            },
            //学历案目录
            queryRes() {
                let zid = this.bookId
                queryResourceObj(zid).then((res) => { // eslint-disable-line no-unused-vars
                    this.queryResourcelistData = res.data
                    this.ResisBuy = res.data.isBuy
                    this.ResourcelistData = res.data.list
                    this.totalPrice = res.data.totalPrice
                    this.totalDiscount = res.data.totalDiscount
                    console.log(res.data.list, "bbbbbbbbbbbbbbbbbbb")
                })
            },
            buyOneD(data) {
                this.buyDialog = true
                this.reeName = data.resourceName
                this.Dprice = data.price
                this.DfolderId = data.folderId //目录id
            },
            //检查余额
            checkAmount() {
                let obj = {
                    bookId: Number(this.bookId),
                    totalPrice: this.totalPrice,
                    totalDiscount: this.totalDiscount
                }
                checkAmountObj(obj).then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        this.checkAmountData = res.data
                        let totalDiscount = res.data.totalDiscount
                        let sumAmount = res.data.sumAmount
                        this.HtotalPrice = totalDiscount - sumAmount
                    }

                })
            },
            //购买单个学历案
            toBuyOne() {
                let params = {
                    bookId: Number(this.bookId),
                    folderId: this.DfolderId,
                    resourceId: 'null'
                }
                buyOneObj(params).then((res) => { // eslint-disable-line no-unused-vars
                    this.buyDialog = false
                    if (res.code == 300) {
                        console.log(res.code, '进来了吗')
                        this.InsuDialog = true
                    } else if (res.code == 200) {
                        this.getStuByUser()
                        this.buyCgDialog = true
                    }
                    console.log(res.code, '99399393')

                })
            },
            //购买单个整本书
            buyOneBook() {
                this.checkAmount()
                if (this.checkAmountData.account > this.HtotalPrice) {
                    this.buyDialogA1 = true
                }else if (this.checkAmountData.sumAmount >= this.checkAmountData.totalDiscount) {
                    this.buyDialogA2 = true
                }else {
                    this.InsuDialog= true
                }
            },
            buyTBookA() {
                let params = {
                    bookId: Number(this.bookId),
                    totalPrice: this.totalPrice,
                    totalDiscount: this.totalDiscount
                }
                buyOneBookObj(params).then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        this.getStuByUser()
                        this.queryRes()
                        this.buyCgDialog = true
                    } else if (res.code == 300) {
                        this.InsuDialog = true
                    }
                })
            },
            buyTBookA1() {
                let params = {
                    bookId: Number(this.bookId),
                    totalPrice: this.totalPrice,
                    totalDiscount: this.totalDiscount
                }
                buyOneBookObj(params).then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        this.getStuByUser()
                        this.queryRes()
                        this.buyDialog = false
                        this.buyDialogA1= false
                        this.buyCgDialog = false
                    } else if (res.code == 300) {
                        this.InsuDialog = true
                    }
                })
            },

            buyTBookB() {
                let params = {
                    bookId: Number(this.bookId),
                    totalPrice: this.totalPrice,
                    totalDiscount: this.totalDiscount
                }
                buyOneBookObj(params).then((res) => { // eslint-disable-line no-unused-vars
                    if (res.code == 200) {
                        this.getStuByUser()
                        this.queryRes()
                        this.buyCgDialog = true
                    } else if (res.code == 300) {
                        this.InsuDialog = true
                    }
                })
            },
            tobuyCg() {
                this.queryRes()
                this.buyDialog = false
                this.buyCgDialog = false

            },

            // 跳转到充值
            toRecharge() {
                this.$router.push({
                    path: "/mainb/recharge",
                    query: {
                        EducationCase: this.EducationCase,
                        stu: this.stu,
                    }
                });
            },
            // 跳到目录页面
            ToCourseware() {
                this.$router.push({
                    path: "/mainb/courseware",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag:this.clickFlag,
                        stu: this.stu,
                    }
                });
            },
            append(data) {
                console.log(data, '11')
            },
            // 点击购买弹框
            BtnbuyDialog(data) {
                console.log(data, '这是点击拿到的数据')
                this.buyDialog = true
            },
            // 单元卷练习
            toUnitTest(data) {
                if(data.isBuy==0) {
                    this.$router.push({
                        path: "/mainb/UnitTestQuestions",
                        query: {
                            EducationCase: this.EducationCase,
                            clickFlag: this.clickFlag,
                            bookId: this.bookId,
                            stu: this.stu,
                            folderId: data.id,
                            disabled:1,
                            id: 0,
                            ResisBuy:this.ResisBuy
                        }
                    });
                } else {
                    this.$router.push({
                        path: "/mainb/UnitTestQuestions",
                        query: {
                            EducationCase: this.EducationCase,
                            clickFlag: this.clickFlag,
                            bookId: this.bookId,
                            stu: this.stu,
                            folderId: data.id,
                            id: 0,
                            disabled:null,
                            ResisBuy:this.ResisBuy
                        }
                    });
                }

            },
            // 去详情
            tosDetails(data) {
                this.$router.push({
                    path: "/mainb/details",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        bookId: this.bookId,
                        stu: this.stu,
                        folderId: data.folderId,
                        totalPrice:this.totalPrice,
                        totalDiscount:this.totalDiscount,
                        id: data.resourceId,
                        disabled:null,
                        ResisBuy:this.ResisBuy,
                        catalogue:1
                    }
                });
            },
            tosNoBuyDetails(data) {
                this.$router.push({
                    path: "/mainb/details",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        bookId: this.bookId,
                        disabled: 1,
                        stu: this.stu,
                        folderId: data.folderId,
                        id: data.resourceId,
                        NoBuy: 1,
                        ResisBuy:this.ResisBuy,
                        catalogue:1
                    }
                });
            },
            //查询列表
            listObj() {


            },

        },
        components: {
            // 图标

            //注册图标组件名称
        }
    }
</script>
<style lang="scss" scoped>
    .case-conent {
        margin: 20px auto;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 0.9;
        display: flex;
        position: relative;
        /*左侧科目基本信息*/
        .catalogueLf {
            width: 304px;
            background: #ccc url("../../assets/images/inf-02.png") no-repeat;
            background-size: cover;
            height: 100%;
            position: absolute;
            //background: #37AEFF;
            text-align: center;
            color: #fff;
            opacity: 1;

            .inf-01 {
                width: 100%;
                height: 203px;
                position: relative;
                background: url("../../assets/images/inf-01.png") no-repeat;
                background-size: cover;

                .inf-back {
                    width: 111px;
                    height: 60px;
                    position: absolute;
                    cursor: pointer;
                    left: 0;
                    background: url("../../assets/images/inf-back.png") no-repeat;
                    background-size: 111px 60px;
                }

                .triangle {
                    width: 15px;
                    height: 30px;
                    position: absolute;
                    background: url("../../assets/images/inf-tg.png") no-repeat;
                    background-size: cover;
                    top: 188px;
                    left: 289px;
                }

                .book-Info {
                    width: 90%;
                    padding-top: 20px;
                    box-sizing: border-box;
                    margin: auto;

                    img {
                        height: 120px;
                        margin: auto;
                    }

                    .T-01 {
                        width: 100%;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: bold;
                        color: #fcea85;
                    }

                    .T-02 {
                        width: 100%;
                        display: flex;
                        font-weight: 100;
                        color: #fff;

                        .d-rg {
                            width: 100%;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .inf-02 {
                width: 89%;
                margin: auto;
                height: 72%;

                .inf-time {
                    width: 90%;
                    height: 40px;
                    margin: auto;
                    line-height: 30px;
                    padding-top: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    overflow: hidden;
                    color: #666;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .inf-j {
                    width: 173px;
                    height: 38px;
                    margin-top: 10px;
                    background: url("../../assets/images/inf-j.png") no-repeat;
                    background-size: 173px 38px;
                }

                .inf-cone {
                    width: 90%;
                    height: 80%;
                    overflow-y: auto;
                    color: #666;
                    text-align: left;
                    margin: 20px auto;
                }
            }
        }

        /*右侧目录基本信息*/
        .catalogueRg {
            width: 100%;
            padding: 0 0px 0 304px;
            background-color: #fff;
            height: 100%;

            ::v-deep .el-tree-node__content {
                display: flex;
                align-items: center;
                vertical-align: center;
                height: 36px;
                line-height: 36px;
                cursor: pointer;
            }
            ::v-deep .el-icon-caret-right1 {
                border-radius: 50%;
                margin-right: 6px;
                margin-top: -3px;
                vertical-align: middle;
            }
            ::v-deep .el-tree-node__expand-icon {
                position: absolute;
                z-index: 999;
                margin: auto;
                display: flex;
                padding: 3px 3px;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            ::v-deep .el-tree-node__expand-icon {
                cursor: pointer;
                color: #fff;
                /*font-size:12px;*/
                /*transform: rotate(0);*/
                /*transition: transform .3s ease-in-out;*/
            }

            .tRreeName {
                width: 100%;
                height: 45px;
                background-color: #fcd278;
                font-weight: 700;
                color: #000;
                text-align: center;
                line-height: 45px;
            }

            .inf-03 {
                width: 100%;
                height: 64px;
                padding-top: 20px;
                background: url("../../assets/images/inf-03.png") no-repeat center 20px;
                background-size: 273px 64px;
                position: relative;

                .inf-zT {
                    width: 300px;
                    height: 60px;
                    padding-right: 50px;
                    box-sizing: border-box;
                    position: absolute;
                    top: 10px;
                    right: 0px;
                    float: right;

                    h2 {
                        text-decoration: line-through;
                        color: #999;
                        text-align: right
                    }

                    .zT {
                        width: 320px;
                        height: 30px;
                        line-height: 30px;
                        padding-top: 6px;
                        text-align: right;
                        display: flex;

                        ::v-deep.btnA {
                            height: 28px;
                            line-height: 28px;
                            padding: 0 6px;
                        }

                        span {
                            color: #008DF0;
                            margin-right: 20px
                        }
                    }
                }
            }

            .tRreeBox {
                width: 97%;
                margin: 20px auto;
                background-color: #fff;
                padding: 0 60px;
                box-sizing: border-box;
                height: 78%;
                overflow-y: auto;
                color: #000;
                position: relative;
                text-align: center;

                .custom-tree-node {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right:100px;
                    box-sizing: border-box;
                    .circle1 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        margin-top: -3px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;
                        background: #fcd278;
                        color: #fff;
                        img {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                            margin-top: 0px;
                            margin-right:10px;
                        }
                    }
                    .circle2 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        background: #0090DA;
                        color: #fff;
                        img {
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 0px !important;
                            margin-right:10px;
                        }
                    }
                    .circle3 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;
                        background: rgba(255, 104, 32, 1);
                        color: #fff;
                    }
                    .ico-tree-2 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;
                        img {
                            width: 16px; height: 16px;vertical-align: middle;
                        }
                    }
                }
            }
        }


    }
    .buyCg {
        width: 140px;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        margin: auto;
        flex-direction: column;
        height: 50px;
        line-height: 50px;

        i {
            color: #6eb864;
            margin-right: 10px;
            margin-top: 2px;
        }

        span {
        }
    }

    .buyDialogA1 {
        ::v-deep.el-dialog__header {
              padding: var(--el-dialog-padding-primary);
              font-weight: 800;
              height: 46px;
              line-height: 46px;
              box-sizing: border-box;
              padding-left: 16px;
              border-bottom: 0px solid #f2f2f2;
              padding-bottom: 0px;
          }

        ::v-deep.el-dialog__body {
            padding: 0px;
            color: #606266;
            word-break: break-all;
        }

        .buyDialogA1-01 {
            width: 100%;
            border-bottom: 1px solid #f2f2f2;

            p {
                line-height: 30px;
                text-align: center;
                padding: 0;
                margin: 0;
                color: #999;
            }

            .logA {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;

                span {
                    color: #FF5A00;
                }
            }

        }

        .buyDialogA1-02 {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid #f2f2f2;

            p {
                line-height: 30px;
                text-align: center;
                padding: 0;
                margin: 0;
                color: #999;
            }

            .logA {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;

                span {
                    color: #000;
                }
            }

        }

        .buyDialogA1-03 {
            width: 100%;
            height: 50px;
            text-align: center;
            background-color: #008DF0;
            padding-top: 20px;
            color: #fff;
        }

        .buyDialogA1-03:hover {
            background-color: #0EBEC6;
        }
        .buyDialogA1-04 {
            width: 100%;
            height: 200px;
            text-align: center;
            padding-top: 20px;
            color: #000;
            .el-icon-success {
                  color: #6eb864;
                  vertical-align: middle;
                  margin-right: 10px;
              }
            .el-icon-warning {
                color: #cf9236;
                vertical-align: middle;
                margin-right: 10px;
            }
        }
        .buyDialogA1-05 {
            width: 100%;
            height: 40px;
            padding-bottom: 30px;
            text-align: center;
            padding-top: 20px;
            color: #fff;
        }
    }



</style>
