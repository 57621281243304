//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 学历案目录：
export function queryResourceObj(zid) {

    return request({
        url: '/study/resources/queryResourceDetail/'+zid,   // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 检查余额：
export function checkAmountObj(obj) {
    return request({
        url: '/study/students/checkAmount',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 购买单个学历案：
export function buyOneObj(obj) {
    return request({
        url: '/study/transactions/buyOne',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 购买单本图书：
export function buyOneBookObj(obj) {
    return request({
        url: '/study/students/buyOneBook',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询个人信息：
export function getStuByUserNameObj(obj) {
    return request({
        url: '/study/students/getStuByUserName',   // url = base url + request url
        method: 'get',

    })
}
